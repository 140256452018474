import React from "react";
import { isChrome, isFirefox, isIeEdge, isSafari, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/bmpage.module.scss";
import Data from "./data/bmpage.json";
class bmPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: "none",
      pageData: props.data,
      data: Data,
      isLoading: true
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    else if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
   else if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
   else if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();
        this.state.isSafari = isSafari();

        if (isChrome()) {
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({ data: browserData });
        }
        if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
        if (isSafari()) {
          const browserData = Object.assign(this.state.data, this.state.data.safari);
          this.setState({ data: browserData });
        }
        if (isIeEdge()) {
          const browserData = Object.assign(this.state.data, this.state.data.edge);
          this.setState({ data: browserData });
        }
        if (this.props.browsers) {
          this.showBrowsers(this.props.browsers);
        } else {
          this.setState({ display: "block" });
        }
      });
    }

      //OS Detection
      document.querySelectorAll('.animCTA').forEach(p => {

          p.innerText = this.state.data.mainCTA;
  });
}

  render() {
    return (
      <>
      <section style={{ display: this.state.display }}>
        <div id={Styles.bmPage} style={{ display: this.state.display }}>
          <main>
            <div className = {Styles.modal}>
            <img className = {Styles.logo} src={this.state.data.logo} />
              <h1 className = {Styles.hc_param}>{this.state.data.headline}</h1>
              <h2 className={Styles.subList}>{this.state.data.subheadline}</h2>
              <ul className = {Styles.bullets}>
              {this.state.data.bullets != undefined ? this.state.data.bullets.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
              </ul>
              <button className="animCTA" data-cy="cta" onClick = {() => {if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) return; else return window.triggerInstall()} }
                dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
              {this.props.children}
            </div>
          </main>
        </div>
        {this.props.footer}
        </section>
      </>
    );
  }
}

export default bmPage;
